import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Analytics from '../Analytics'
import Help from './Help'
import Loader from './Loader'
import styles from './LoginWithEmailID.module.scss'
import Button from './Button'
import Config from '../Config'
import Arrow from '../img/lovies-2023/arrow.svg'

export function LoginWithEmailID ({ section, currentSection, login }) {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { EmailID } = useParams()
	const id = useRef()

	const validate = async () => {
		if (id.current.value) {
			setLoading(true)
			const data = await axios.post(`${Config.endpoint}/lovie_login2023`, {
				entrantID: id.current.value,
				emailID: EmailID
			})

			setLoading(false)

			if (data.data.user) {
				Analytics.sendLogin(data.data.user.EntrantID)
				login(data.data.user)
			} else {
				setError('Invalid Entrant ID')
				Analytics.sendEvent('Invalid Login')
			}
			// login(id.current.value);
		} else {
			setError('You need to enter your entrant ID')
		}
	}

	const kp = (e) => {
		setError(null)
		if (e.key === 'Enter') {
			validate()
		}
	}

	useEffect(() => Analytics.sendEvent('Recorder Login Page'), [])

	return (
		<>
			<div id="ScreenHome" className="section-home flex flex-force">
				<div className="">
					<h1 className={styles.headline}>
						Welcome to the 13th Annual Lovie Awards
					</h1>

					{/* <p style={{ fontSize: 24 }}> */}
					<p className={styles.subhead}>
						Have your 7 Words of Lovie Speech? Enter Your Entrant ID to Upload.
					</p>
					<br />

					<div className={styles.form}>
						<input className={styles.input} ref={id} type="text" placeholder="Enter ID Here" onKeyPress={kp} />

						<Button
							onClick={validate}
							wide
						>
							I'm Ready!
							<img src={Arrow} style={{ marginLeft: '20px' }} />
						</Button>
					</div>

					<div className="error">
						{error || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></span>}
					</div>

					<Help />
				</div>
			</div>

			{loading && <Loader text="Logging in..." />}
		</>
	)
}
