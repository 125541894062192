import React from 'react'
import styles from './Help.module.scss'

export default function Help () {
	return <div className={styles.help}>
		<div className={styles.primary}>
			Have Any
			<br/>
			Questions Or
			<br/>
			Need Assistance?
		</div>

		<div className={[styles.primaryMobile].join(' ')}>
			Have Any
			Questions Or
			Need Assistance?
		</div>

		<div className={styles.secondary}>
			We're here to help!&nbsp;
			<a href="mailto:evey@lovieawards.com">Email your questions</a> to Customer Service,
			Evey Long, or call us at +1.212.675.3555,
			Monday-Friday, 8:30am-5pm GMT.
		</div>
	</div>
}
