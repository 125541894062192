import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import heart from './img/2021/header-logo.png'
import sponsorLogo from './img/2021/pinterest.svg'
import styles from './Header.module.scss'
import c from 'classnames'
// import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import useDimensions from 'react-use-dimensions'

export default function Header ({ section = 'main', showAlert = true }) {
	const [url, setURL] = useState(null)
	const [text, setText] = useState(null)
	// const [opacity, setOpacity] = useState(0)
	const location = useLocation()
	const [ref, { height }] = useDimensions()

	useEffect(() => {
		if (section === 'landing' || section === 'browse') {
			if (location.pathname === '/browse' || location.pathname.indexOf('/browse') !== -1 || location.pathname.indexOf('/search') !== -1) {
				setURL('/')
				setText('Featured Moments')
			} else {
				setURL('/browse')
				setText('All Speeches')
			}
		}
	}, [location])

	// useScrollPosition(({ prevPos, currPos }) => {
	// 	let opacity = currPos.y / -200
	// 	opacity = opacity > 1 ? 1 : opacity
	// 	if (opacity <= 1) {
	// 		setOpacity(opacity)
	// 	}
	// })

	return <div className={styles.headerHolder}>
		<header className={styles.root}>
			<a href="https://www.lovieawards.com" className={c(styles.logo)}>
				<img src={require('./img/lovies-2023/logo.svg').default} />
			</a>
		</header>
	</div>
}
