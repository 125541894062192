import React from 'react'

import Twitter from './img/twitter.svg'
import X from './img/lovies-2023/x.svg'
import Facebook from './img/lovies-2023/facebook.svg'
import Instagram from './img/lovies-2023/instagram.svg'
import Linkedin from './img/lovies-2023/linkedin.svg'
import Youtube from './img/lovies-2023/youtube.svg'

import PartnerList from './PartnerList'
import styles from './Footer.module.scss'

import Webbys from './img/anthem/webby-logo.svg'
import LoviesLogo from './img/lovies-2023/lovie_logo_large.svg'

export default function Footer ({ full = true }) {
	return (
		<>
			{full && <footer>
				<PartnerList />

				<div className={styles.root}>
					<div className={styles.left}>
						<a href="https://www.linkedin.com/company/the-lovie-awards/" target="_blank" rel="noopener noreferrer">
							<img src={LoviesLogo} alt="The Lovie Awards" />
						</a>
					</div>

					<div className={styles.left}>
						<div className={styles.social}>
							<a href="https://www.instagram.com/thelovieawards/" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>
							<a href="https://www.facebook.com/thelovieawards" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>
							<a href="https://twitter.com/lovieawards?lang=en" target="_blank" rel="noopener noreferrer"><img src={X} alt="Twitter" /></a>
							<a href="https://youtube.com/lovieawards" target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube" /></a>
							<a href="https://www.linkedin.com/company/the-lovie-awards" target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="LinkedIn" /></a>
						</div>

						<div className={styles.links}>
							<a href="https://www.webbyawards.com/contact-us/" target="_blank" rel="noopener noreferrer">Contact Us</a>
							<a href="http://www.iadas.net/" target="_blank" rel="noopener noreferrer">IADAS</a>
							<a href="https://www.webbyawards.com/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
							<a href="https://www.webbyawards.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
						</div>
					</div>

					<div className={styles.right}>
						<a
							href="https://www.webbyawards.com/"
							target="_blank"
							rel="noopener noreferrer"
							className={styles.logoLink}
						>
							The Webby Awards
						</a>
						{/* <div className={styles.copy}>&copy; 2023 Webby Awards. All rights reserved.</div> */}
					</div>
				</div>
			</footer>}
		</>
	)
}
