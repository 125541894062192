import React from 'react'
import VideoList from './VideoList'
import Help from './Help'
import styles from './ScreenWelcome.module.scss'
import Button from './Button'
import Arrow from '../img/lovies-2023/arrow.svg'

export default function WinningEntryList ({ user = null, section, currentSection, setPiece }) {
	if (!user) {
		return <div />
	}

	return (
		<div className="">
			<div className="section">
				<div className={styles.greeting}>Hi, {user[0].Organization}</div>

				<p className={styles.intro}>
					Congratulations on your outstanding achievement as a winner of the 13th Annual Lovie Awards! 7 Words of Lovie speeches will be showcased in our all-new Lovie Awards Winners Gallery, launching 9 November 2023.
					<br/><br/>
					To share your speech for your award-winning work, simply click the &lsquo;Upload&rsquo; button below.
				</p>

				<div className={styles.pieceContainer}>
					{user.map((project, p) =>
						<div key={p} className={styles.project}>
							<div>
								<div className={styles.upload}>
									<div className={styles.pieceTitle}>{project.PieceTitle}</div>
									{project.awards.filter(award => award.AwardLevel !== 'Shortlisted').sort((a, b) => {
										const order = { Gold: 1, Silver: 2, Bronze: 3 }
										return order[a.AwardLevel] - order[b.AwardLevel]
									}).map((award, a) =>
										<div key={a} className={styles.pieceAward}>
											<div className={styles.pieceAwardTitle}>
												<AwardLevel level={award.AwardLevel} />
												{award.pv
													? <AwardLevel level="pv" />
													: <></> }
											</div>

											{award.MediaType} / {award.CategoryType} / {award.Category}
										</div>
									)}
								</div>

								<VideoList key={project.PieceID} pieceID={project.PieceID} />
							</div>

							<Button
								style={{ display: 'flex', alignItems: 'center' }}
								onClick={() => setPiece(project)}
							>
									Upload
								<img src={Arrow} style={{ marginLeft: 11, height: 16 }} />
							</Button>
						</div>
					)}
				</div>

				<Help />
			</div>
		</div>
	)
}

export function AwardLevel ({ level }) {
	return (
		<div className={`${styles.awardLevel} ${styles[level.toLowerCase()]}`}>
			{level === 'pv' ? 'PL' : level.charAt(0).toUpperCase()}
		</div>
	)
}
