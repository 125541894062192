import React, { useState } from 'react'
import Identify from '../utils/Identify'
// import VideoCapture from './VideoCapture/VideoCapture';
import Progress from './Progress'
import Analytics from '../Analytics'
import Help from './Help'
import styles from './ScreenRecorder.module.scss'
import Button from './Button'
import ArrowLeft from '../img/lovies-2023/arrow-left.svg'
import Arrow from '../img/lovies-2023/arrow.svg'

export function ScreenRecorderPre ({ goHome, piece = null, section, currentSection, setShow, official }) {
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'
	const safari = identifyInstance.safari()
	const [enable, setEnable] = useState(null)

	const [progress, setProgress] = useState(1)

	const finalize = (videoID) => {
		setShow(videoID)
	}

	return <>
		<div className={styles.root}>
			<div>
				<div
					onClick={goHome}
					className={styles.backButton}
				>
					<img src={ArrowLeft} style={{ marginRight: 11, height: 18 }} />
					&nbsp;&nbsp;Back to Winning Projects
				</div>

				<div>
					<div className={styles.uploadContent}>
						<div className={styles.uploadContainer}>
							<h1>Upload Speech for <div className={styles.pieceTitle}>{piece ? piece.PieceTitle : null}</div></h1>
							<div className={styles.uploadInstructions}>
								<p>
									<strong>What happens next?</strong>
								</p>
								<p>
									Upload your speech here and it will be posted to the all-new Lovie Awards Winners gallery on 9 November. Don&apos;t forget to be creative with your 7 Words of Lovie! 								</p>
							</div>

							<Button wide onClick={() => setShow()} style={{ display: 'inline-flex', alignItems: 'center' }}>
								Upload A Video
								<img src={Arrow} style={{ marginLeft: 11, height: 16 }} />
							</Button>
						</div>
						<Help />
					</div>
				</div>
			</div>
			<Progress progress={progress} />
		</div>
	</>
}
