import React from 'react'
import styles from './PartnerList.module.scss'

const logoContext = require.context('./img/lovies-2023/logos', true, /\.svg$/)

const links = {
	'wp-engine': 'https://wpengine.com/',
	canva: 'https://www.canva.com/',
	verizon: 'https://www.verizon.com/',
	naacp: 'https://www.naacp.org/',
	snapchat: 'https://www.snapchat.com/',
}

const Logos = ({ logos }) => {
	return <div className={styles.logos}>
		{logos.map(logo =>
			<a
				key={logo}
				href={links[logo]}
				target="_blank"
				rel="noreferrer"
				className={styles.logo}
			>
				<img
					alt={logo}
					src={logoContext(`./${logo}.svg`).default}
				/>
			</a>
		)}
	</div>
}

const PartnerList = () => {
	return <div className={styles.root}>
		<div className={styles.inner}>
			<div style={{ textAlign: 'center' }}>
				<a rel="noreferrer" target="_blank" href="https://old.lovieawards.com/sponsors/" className={styles.heading}>Presented By</a>
			</div>

			<Logos logos={[
				'snapchat',
			]} />
		</div>
	</div>
}

export default PartnerList
