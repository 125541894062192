import { React, useEffect } from 'react'
import ReactDOM from 'react-dom'
// import loader from '../img/heart.svg';
import styles from './Loader.module.scss'
// import Logo from './../img/anthem/webby-logo-black.svg'
import Logo from './../img/lovies-2023/logo_notext.svg'

export default function Loader ({ text = 'Uploading your speech<br />Give it a minute' }) {
	const el = document.createElement('div')
	useEffect(() => {
		document.body.appendChild(el)

		return () => {
			document.body.removeChild(el)
		}
	}, [])

	return (
		ReactDOM.createPortal(
			<div className={styles.root}>
				<div className={styles.inner}>
					{/* <img src={loader} alt="loading" /> */}
					<img src={Logo} />

					<span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
				</div>
			</div>,
			el
		)
	)
}
