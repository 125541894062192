import React, { useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Button from '../recorder/Button'
import styles from './HomeHeader.module.scss'
import Floater from './Floater'
import c from 'classnames'

const names = `
	Dr. Jane Goodall
	Megan Rapinoe
	Adam McKay
	Naomi Osaka
	Trevor Noah
	Robin Roberts
	The Cast of Sesame Street
	Bindi Irwin
	NASA
	LeVar Burton
	Nat Geo
	American Red Cross
`
	.split('\n')
	.map(s => s.trim())
	.filter(s => s)

const HomeHeader = ({ go }) => {
	return <div className={styles.root}>
		<div className={styles.floaterOuterWrapper}>
			<div className={styles.floaterWrapper}>
				<Floater />
			</div>
		</div>

		<div className={styles.vert}>
			<div>
				<div className={styles.topText}>the Inaugural</div>
				<br />

				<div className={styles.biglogo}>
					{/* <img width="100%" src={require('../img/anthem/webby-landing-logo-color.svg').default} alt="Webby Awards" /> */}
				</div>

				<div className={styles.host}>
				Hosted by actor &amp; activist Jay Ellis
				</div>

				<div className={styles.tickerTitle}>
				With Call to Action Speeches &amp; Appearances By:
				</div>

				<div className={styles.ticker}>
					{[1, 2].map(i =>
						<div key={i} className={c(styles.tickerNames, styles[`ticker${i}`])}>
							{names.map(name =>
								<span key={name}>&bull;&nbsp;&nbsp;&nbsp;&nbsp;{name}&nbsp;&nbsp;&nbsp;&nbsp;</span>
							)}
						</div>
					)}
				</div>

				<div className={styles.buttons}>
					<Button onClick={go} outline wide>Featured moments</Button>
					<Link component={Button} to="/browse" outline wide>
					ALL SPEECHES
					</Link>
				</div>
			</div>
		</div>
	</div>
}

export default HomeHeader
