import React, { useState, useEffect } from 'react'
import Identify from '../utils/Identify'
// import VideoCapture from './VideoCapture/VideoCapture';
import VideoRecorderIOS from './VideoRecorderIOS'
import Progress from './Progress'
import Analytics from '../Analytics'
import Help from './Help'
import styles from './ScreenRecorder.module.scss'
import { BackIcon } from '../BackIcon'
import ArrowLeft from '../img/lovies-2023/arrow-left.svg'

export default function ScreenRecorder ({ goHome, piece = null, section, currentSection, setShow, official }) {
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'
	const safari = identifyInstance.safari()
	const [enable, setEnable] = useState('upload')

	const [progress, setProgress] = useState(1)

	const finalize = (videoID) => {
		setShow(videoID)
	}

	useEffect(() => Analytics.sendEvent('Record Page'), [])

	return <>

		<div className="section-recorder">
			<div className="recorder">
				<div
					onClick={goHome}
					className={styles.backButton}
				>
					<img src={ArrowLeft} style={{ marginRight: 11, height: 18 }} />
					&nbsp;&nbsp;Back to Winning Project
				</div>

				<div className="">
					<div className={styles.uploadContainer}>
						{enable === 'upload'
							? <VideoRecorderIOS piece={piece} setProgress={setProgress} finalize={finalize} official={official} />
							: null}
					</div>
				</div>
			</div>

			<Progress progress={progress} />
		</div>
	</>
}
